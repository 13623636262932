import { render, staticRenderFns } from "./EventDetailsReport.vue?vue&type=template&id=bfd72436&scoped=true"
import script from "./EventDetailsReport.vue?vue&type=script&lang=js"
export * from "./EventDetailsReport.vue?vue&type=script&lang=js"
import style0 from "./EventDetailsReport.vue?vue&type=style&index=0&id=bfd72436&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfd72436",
  null
  
)

export default component.exports