var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"event-details-report"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("REPORTS.EVENT_DETAILS_REPORT_PAGE.TITLE")))]),_c('v-app',[_c('form',{staticClass:"mt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"event"}},[_vm._v(_vm._s(_vm.$t("REPORTS.EVENT_DETAILS_REPORT_PAGE.EVENT")))]),_c('v-autocomplete',{attrs:{"filled":"","solo":"","items":_vm.events,"item-text":"eventName","item-value":"id","placeholder":_vm.$t('REPORTS.EVENT_DETAILS_REPORT_PAGE.EVENT_PLACEHOLDER')},on:{"change":_vm.selectEvent},model:{value:(_vm.selectedEvent),callback:function ($$v) {_vm.selectedEvent=$$v},expression:"selectedEvent"}})],1),_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"event"}},[_vm._v(_vm._s(_vm.$t("REPORTS.EVENT_DETAILS_REPORT_PAGE.DOCTOR")))]),_c('v-autocomplete',{attrs:{"filled":"","solo":"","items":_vm.assignedDoctors,"item-text":"name","return-object":"","placeholder":_vm.$t('REPORTS.EVENT_DETAILS_REPORT_PAGE.DOCTOR_PLACEHOLDER')},model:{value:(_vm.selectedDoctor),callback:function ($$v) {_vm.selectedDoctor=$$v},expression:"selectedDoctor"}})],1)]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"btn-primary",attrs:{"disabled":!_vm.selectedEvent || !_vm.selectedDoctor},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t("COMMON.SUBMIT")))])],1)])]),(_vm.showTable)?[(_vm.selectedDoctor.doctorId === 'all')?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"event"}},[_vm._v(_vm._s(_vm.$t("REPORTS.EVENT_DETAILS_REPORT_PAGE.SPECIALITY")))]),_c('v-autocomplete',{attrs:{"filled":"","solo":"","items":_vm.specialities,"item-text":"TextEn","item-value":"value","placeholder":_vm.$t(
                    'REPORTS.EVENT_DETAILS_REPORT_PAGE.SPECIALITY_PLACEHOLDER'
                  )},on:{"change":_vm.selectSpeciality},model:{value:(_vm.selectedSpeciality),callback:function ($$v) {_vm.selectedSpeciality=$$v},expression:"selectedSpeciality"}})],1),_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"event"}},[_vm._v(_vm._s(_vm.$t("REPORTS.EVENT_DETAILS_REPORT_PAGE.GOVERNORATE")))]),_c('v-autocomplete',{attrs:{"filled":"","solo":"","items":_vm.governorates,"item-text":"governorate_name_en","item-value":"value","placeholder":_vm.$t(
                    'REPORTS.EVENT_DETAILS_REPORT_PAGE.GOVERNORATE_PLACEHOLDER'
                  )},on:{"change":_vm.selectGovernorate},model:{value:(_vm.selectedGovernorate),callback:function ($$v) {_vm.selectedGovernorate=$$v},expression:"selectedGovernorate"}})],1)])])])]:_vm._e(),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"btn-success"},[_vm._v(_vm._s(_vm.$t("COMMON.EXTRACT")))])],1),_c('h3',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("EVENT.EVENT_DETAILS.ASSIGNED_DOCTORS")))]),(_vm.doctors.length > 0)?_c('b-table',{attrs:{"striped":"","hover":"","fields":_vm.$t('DOCTOR.FIELDS'),"items":_vm.doctors},scopedSlots:_vm._u([{key:"cell(speciality)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.getSpeciality(row.item.speciality))+" ")]}},{key:"cell(governorate)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.getGovernorate(row.item.governorate))+" ")]}},{key:"cell(questionnaireSubmitted)",fn:function(row){return [(!row.item.questionnaireSubmitted)?_c('b-button',{staticClass:"mr-2 btn-primary",attrs:{"size":"sm","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t("REPORTS.EVENT_DETAILS_REPORT_PAGE.NOT_SUBMITTED"))+" ")]):_c('b-button',{staticClass:"mr-2 btn-primary",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$router.push({
              name: 'Questionnaire Details',
              params: {
                eventId: _vm.selectedEvent,
                doctorId: row.item.doctorId,
              },
            })}}},[_vm._v(" "+_vm._s(_vm.$t("REPORTS.EVENT_DETAILS_REPORT_PAGE.GO_TO_QUESTIONNAIRE"))+" ")])]}}],null,false,119863553)}):[_c('b-alert',{staticClass:"mt-5",attrs:{"show":""}},[_vm._v("Cannot find anything with the current filters")])]]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }